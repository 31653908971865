.graph_frame{
    position: absolute;
    width: 70px;
    height: 100px;
    top: 0px;
    left: 115px;
    background-color: rgba(255, 192, 203, 0);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.bar1{
    width: 20px;
    height: 40px;
    top: 60px;
    position: relative;
    background-color:var(--ax-green);
    animation-name: bar1_move;
    animation-duration: 4s;
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.bar2{
    width: 20px;
    height: 60px;
    top: 40px;
    position: relative;
    background-color:var(--ax-pink);
    animation-name: bar2_move;
    animation-duration: 5s;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.bar3{
    width: 20px;
    height: 100px;
    position: relative;
    background-color:var(--ax-grey);
    animation-name: bar3_move;
    animation-duration: 10s;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes bar3_move {
    0%{
        height: 100px;
        top: 0px;
    }
    70%{
        height: 30px;
        top: 70px;
        background-color: white;
    }
    100%{
        height: 100px;
        top: 0px;
    }
}



@keyframes bar2_move {
    0%{
        height: 60px;
        top: 40px;
    }
    50%{
        height: 20px;
        top: 80px;
    }
    100%{
        height: 60px;
        top: 40px;
    }
}


@keyframes bar1_move {
    0%{
        height: 40px;
        top: 60px;
    }
    20%{
        height: 70px;
        top: 30px;
    }
    100%{
        height: 40px;
        top: 60px;
    }
}