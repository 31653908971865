.dash_title{
    font-size: 42px;
    color: white;
    text-align: center;
    width: 100%;
    position: relative;
}

.back_button{
    position: absolute;
    top: 5px;
    left: 5px;
    height: 65px;
    width: 220px;
    border-radius: 5px;
    background-color: var(--ax-purple);
    cursor: pointer;
    z-index: 2;
    transition: .7s;
}
.back_button:hover{
    transition: .3s;
    background-color: var(--ax-pink);
}

.kpi_shelf{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.cost_breakdown_holder{
    width: 90%;
    left: 5%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}