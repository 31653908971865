.advanced_holder{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 500px;
    background-color: rgba(0, 68, 59, 0);
}

.ax_fake{
    height: 500px;
    width: 400px;
    background-color: white;
}