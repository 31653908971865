.app_header{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: fixed;
    width: 100%;
    background-color: var(--ax-black);
    top: 0;
    left: 0;
    padding:5px;
    /* background-color: #a5a5a5; */
    /* padding: 5px; */
    z-index: 99;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--ax-purple);
}


.top_img_ph{
    height: 25px;
    padding-left: 5px;
    margin: 3px;
}

.top_img_em{
    height: 25px;
    padding-left: 10px;
    margin: 3px;
}

.top_preview_button{
    background-color: var(--ax-green);
    height: 25px;
    position: relative;
    top: 5px;
    left: 75%;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 3px;
    cursor: pointer;
}

.top_preview_text{
    font-weight: bolder;
    position: relative;
    top: -10px;
    font-size: 14px;
}