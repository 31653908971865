.pi_subtext{
    text-align: left;
    text-indent: 50px;
    color: white;
    font-weight: bolder;
    font-size: 26px;
}

.pi_legend{
    display: flex;
    flex-direction: column;
    height: 400px;
    background-color: rgba(0, 0, 0, 0);
    width: 225px;
    white-space: nowrap;

}

.pi_row{
    display: flex;
    flex-direction: row;
}

.pi_legend_text{

}

.pi_legend_text_g{
    font-size: 32px;
    text-align: left;
    text-indent: 50px;
    color: var(--ax-green);
}

.pi_legend_text_p{
    font-size: 32px;
    text-align: left;
    text-indent: 50px;
    color: var(--ax-pink);
}
.pi_legend_text_pr{
    font-size: 32px;
    text-align: left;
    text-indent: 50px;
    color: var(--ax-purple);
}
.pi_legend_textgy{
    font-size: 32px;
    text-align: left;
    text-indent: 50px;
    color: #737373;
}