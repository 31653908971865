.slogan{
    color: var(--ax-green);
    font-weight: 100;
    font-size: 26px;
    padding: 15px;
}

.slogan_sub{
    color: white;
    font-weight: 100;
    font-size: 18px;
    /* padding-top: 25px; */
    /* padding-left: 30px; */
    /* padding-right: 30px; */
    width: 70%;
    left:15%;
    position: relative
}

.logo_img{
    padding-top:70px;
}