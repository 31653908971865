.node_holder{
    height: 200px;
    width: 200px;
    margin-right: 40px;
    position: relative;
    display: flex;
    justify-content: center;
}

.main_node{
    position: relative;
    height: 160px;
    width: 160px;
    top: 20px;
    margin-right: 40px;
    left: 25px;
    border-radius: 160px;
    background-color: var(--ax-purple);
    z-index: 1;
}

.green1_node{
    position: absolute;
    background-color: var(--ax-green);
    top:20px;
    left:140px;
    z-index: 2;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    animation-name: g1_move;
    animation-duration: 8s;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.green2_node{
    position: absolute;
    background-color: var(--ax-green);
    height: 50px;
    top:95px;
    left:10px;
    z-index: 0;
    width: 50px;
    border-radius: 50px;
    animation-name: g2_move;
    animation-duration: 5s;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.white1_node{
    position: absolute;
    background-color: white;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    top:5px;
    left:20px;
    z-index: 2;
    animation-name: w1_move;
    animation-duration: 5s;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.white2_node{
    position: absolute;
    background-color: white;
    height: 20px;
    width: 20px;
    top:65px;
    left:50px;
    border-radius: 20px;
    z-index: 2;
    animation-name: w2_move;
    animation-duration: 8s;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.white3_node{
    position: absolute;
    background-color: white;
    height: 20px;
    width: 20px;
    top: 30px;
    left: 16px;
    border-radius: 20px;
    z-index: 0;
    animation-name: w3_move;
    animation-duration: 10s;
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.pink1_node{
    z-index:3;
    background-color: var(--ax-pink);
    height: 6px;
    width: 6px;
    border-radius: 6px;
    position: absolute;
    top:40px;
    left:5px;
    animation-name: p1_move;
    animation-duration: 15s;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.pink2_node{
    z-index:3;
    background-color: var(--ax-pink);
    height: 6px;
    width: 6px;
    border-radius: 6px;
    position: absolute;
    top:60px;
    left:15px;
    animation-name: p2_move;
    animation-duration: 15s;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.pink3_node{
    z-index:3;
    background-color: var(--ax-pink);
    height: 6px;
    width: 6px;
    border-radius: 6px;
    position: absolute;
    top:5px;
    left:55px;
    animation-name: p3_move;
    animation-duration: 15s;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.pink4_node{
    z-index:3;
    background-color: var(--ax-pink);
    height: 6px;
    width: 6px;
    border-radius: 6px;
    position: absolute;
    top:60px;
    left:90px;
    animation-name: p4_move;
    animation-duration: 15s;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.pink5_node{
    z-index:3;
    background-color: var(--ax-pink);
    height: 6px;
    width: 6px;
    border-radius: 6px;
    position: absolute;
    top:40px;
    left:95px;
    animation-name: p5_move;
    animation-duration: 15s;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}


@keyframes p5_move {
    0%{
        top:40px;
        left:95px;
    }

    30%{
        top:160px;
        left:10px;
    }
    50%{
        top:80px;
        left:100px;
    }
    70%{
        top: 85px;
        left: 145px;
    }
    100%{
        top:40px;
        left:95px;
    }

}






@keyframes p4_move {
    0%{
        top:60px;
        left:90px;
    }

    30%{
        top:70px;
        left:180px;
    }
    50%{
        top:180px;
        left:180px;
    }
    80%{
        top: 65px;
        left: 45px;
    }
    100%{
        top:60px;
        left:90px;
    }

}






@keyframes p3_move {
    0%{
        top:5px;
        left:55px;
    }

    30%{
        top:10px;
        left:150px;
    }
    50%{
        top:100px;
        left:20px;
    }
    70%{
        top: 65px;
        left: 45px;
    }
    100%{
        top:5px;
        left:55px;
    }

}




@keyframes p2_move {
    0%{
        top:60px;
        left:15px;
    }

    30%{
        top:140px;
        left:5px;
    }
    50%{
        top:100px;
        left:50px;
    }
    70%{
        top: 15px;
        left: 105px;
    }
    100%{
        top:60px;
        left:15px;
    }

}


@keyframes p1_move {
    0%{
        top:40px;
        left:5px;
    }

    40%{
        top:55px;
        left:160px;
    }
    60%{
        top:160px;
        left:150px;
    }
    80%{
        top: 125px;
        left: 175px;
    }
    100%{
        top:40px;
        left:5px;
    }

}






@keyframes w3_move {
    0%{
        top: 30px;
        left: 16px;
    }

    40%{
        top:55px;
        left:117px;
    }
    60%{
        top:50px;
        left:113px;
    }
    80%{
        top: 155px;
        left: 26px;
    }
    100%{
        top: 30px;
        left: 16px;
    }

}





@keyframes w2_move {
    0%{
        top:65px;
        left:50px;
    }

    40%{
        top:55px;
        left:130px;
    }
    60%{
        top:50px;
        left:115px;
    }
    80%{
        top:85px;
        left:155px;
    }
    100%{
        top:65px;
        left:50px;
    }

}



@keyframes w1_move {
    0%{
        top:5px;
        left:20px;
    }

    20%{
        top:0px;
        left:30px;
    }
    50%{
        top:7px;
        left:25px;
    }
    70%{
        top:3px;
        left:8px;
    }
    100%{
        top:5px;
        left:20px;
    }

}

@keyframes g1_move {
    0%{
        top:20px;
        left:140px;
    }

    20%{
        top:35px;
        left:20px;
    }
    50%{
        top:65px;
        left:105px;
    }
    70%{
        top:40px;
        left:20px;
    }
    100%{
        top:20px;
        left:140px;
    }

}

@keyframes g2_move {
    0%{
        top:95px;
        left:10px;
    }

    20%{
        top:125px;
        left:20px;
    }
    50%{
        top:85px;
        left:120px;
    }
    70%{
        top:140px;
        left:90px;
    }
    100%{
        top:95px;
        left:10px;
    }

}