html {
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0E0E0E;
}

:root {
  --ax-green:#00EEBB;
  --ax-pink:#FF00C7;
  --ax-purple:#7F00FF;
  --ax-grey:#393939;
  --ax-darkgrey:#1A1A1A;
  --ax-yellow:#eee700;
  --ax-black:#0E0E0E;
}

.breaker{
  height: 1px;
  width: 90%;
  position: relative;
  left: 5%;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: var(--ax-grey);
}
