.button{
    background-color: var(--ax-purple);
    height: 65px;
    width: 230px;
    border-radius: 5px;
    transition: .5s;
    cursor: pointer;
}

.button:hover{
    background-color: var(--ax-pink);
    transition: .3s;
    color: black;
}

.button_holder{
    display: flex;
    justify-content: center;
}

.button_subtext{
    color: white;
    font-size: 20px;
    font-weight: bolder;
    position: relative;
}

