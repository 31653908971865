.kpi_holder{
    background-color: #0E0E0E;
}

.kpi_subtext{
    text-align: center;
    color: var(--ax-green);
    font-size: 20px;
    text-align: center;
    /* width: 50%;
    left: 25%; */
    position: relative;
    margin-bottom: 30px;
}

.cost_graph_desc{
    text-align: center;
    /* text-indent: 20px; */
    color: rgb(124, 124, 124);
    font-size: 14px;
    padding-bottom: 0px;
    position: relative;
    top:-25px
}



.cost_graph_desc_sub{
    text-align: left;
    text-indent: 20px;
    color: rgb(124, 124, 124);
    font-size: 14px;
    padding-bottom: 0px;
    position: relative;
    top:-25px
}

.cost_graph_desc_sub_pi{
    text-align: left;
    text-indent: 50px;
    color: rgb(124, 124, 124);
    font-size: 14px;
    padding-bottom: 0px;
    position: relative;
    top:-25px
}