.cost_analy_holder{
    display: flex;
    flex-direction: column;
    width: 500px;
    min-width: 500px;
}

.cost_analy_title{
    text-align: left;
    text-indent: 20px;
    color: white;
    font-size: 26px;
}

.cost_analy_text{
    color: white;
    font-weight: lighter;
    font-size: 20px;
    text-align: left;
    text-indent: 50px;
}

.cost_analy_high1{
    color:var(--ax-green);
    font-weight: bolder;
    font-size: 30px;
    padding-left: 20px;
}