.form_holder{
    display: flex;
    flex-direction: column;
    width: 60%;
    left: 20%;
    min-width: 600px;
    position: relative;
}

.form_row{
    display: flex;
    flex-direction: row;
    margin: 10px;
    width: 100%;
    justify-content: center;
    position: relative;
}

.form_input{
    outline: none;
    color: white;
    width: 350px;
    height: 30px;
    font-size: 20px;
    font-family: 'Raleway', sans-serif;
    background-color: rgba(0, 0, 0, 0);
    border-style: solid;
    border-bottom-color:var(--ax-purple);
    border-left-color: rgba(0, 0, 0, 0);
    border-right-color: rgba(0, 0, 0, 0);
    border-top-color: rgba(0, 0, 0, 0);
}

.form_subtext{
    color: var(--ax-green);
    font-size: 20px;
    text-align: center;
    /* width: 165px; */
    position: relative;
}

.contact_subtext{
    text-align: center;
    color: var(--ax-green);
    font-size: 16px;
    text-align: center;
    width: 90%;
    left: 5%;
    position: relative;
    /* margin-bottom: 50px; */
}

.contact_holders{
    display: flex;
    justify-content: space-evenly;
    padding-top: 20px;
}

.contact_img{
    height: 50px;
}