.trust_graphic_holder{
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
}

.trust_graphic_frame{
    width: 830px;
    height: 190px;
    margin-top: 20px;
    background-color: rgba(255, 192, 203, 0);
    position: relative;
}

.trust_graphic_obj1{
    height: 70px;
    width: 230px;
    background-color: var(--ax-darkgrey);
    position: absolute;
    top: 0px;
    left:0px;
    border-radius: 10px;
}

.trust_graphic_obj_txt1{
    color: white;
    font-size: 20px;
    font-weight: bolder;
}

.trust_graphic_obj2{
    height: 70px;
    width: 230px;
    background-color: var(--ax-darkgrey);
    position: absolute;
    top: 120px;
    left:200px;
    border-radius: 10px;
}

.trust_graphic_obj_txt2{
    color: white;
    font-size: 20px;
    font-weight: bolder;
}


.trust_graphic_obj3{
    height: 70px;
    width: 230px;
    background-color: var(--ax-darkgrey);
    position: absolute;
    top: 0px;
    left:400px;
    border-radius: 10px;
}

.trust_graphic_obj_txt3{
    color: white;
    font-size: 20px;
    font-weight: bolder;
}


.trust_graphic_obj4{
    height: 70px;
    width: 230px;
    background-color: var(--ax-darkgrey);
    position: absolute;
    top: 120px;
    left:600px;
    border-radius: 10px;
}

.trust_graphic_obj_txt4{
    color: white;
    font-size: 20px;
    font-weight: bolder;
}


.trust_graphic_line1{
    position: absolute;
    height: 82px;
    width: 82px;
    top: 68px;
    left: 114px;
    border-style: solid;
    border-bottom-color: var(--ax-purple);
    border-left-color: var(--ax-purple);
    border-top-color: rgba(0, 0, 0, 0);
    border-right-color: rgba(0, 0, 0, 0);
    border-radius: 5px;
    animation-name: line1_glow;
    animation-duration: 4s;
    animation-direction: normal;
    animation-iteration-count: infinite;
}


.trust_graphic_line2{
    position: absolute;
    height: 82px;
    width: 82px;
    top: 34px;
    left: 314px;
    border-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0);
    border-left-color: var(--ax-purple);
    border-top-color: var(--ax-purple);
    border-right-color: rgba(0, 0, 0, 0);
    border-radius: 5px;
    animation-name: line2_glow;
    animation-duration: 4s;
    animation-direction: normal;
    animation-iteration-count: infinite;
}


.trust_graphic_line3{
    position: absolute;
    height: 82px;
    width: 82px;
    top: 68px;
    left: 514px;
    border-style: solid;
    border-bottom-color: var(--ax-purple);
    border-left-color: var(--ax-purple);
    border-top-color: rgba(0, 0, 0, 0);
    border-right-color: rgba(0, 0, 0, 0);
    border-radius: 5px;
    animation-name: line3_glow;
    animation-duration: 4s;
    animation-direction: normal;
    animation-iteration-count: infinite;
}



@keyframes line3_glow {
    0%{
        border-bottom-color: var(--ax-purple);
        border-left-color: var(--ax-purple);
    }

    59%{
        border-bottom-color: var(--ax-purple);
        border-left-color: var(--ax-purple);
    }
    
    60%{
        border-bottom-color: var(--ax-purple);
        border-left-color: white;
    }

    80%{
        border-bottom-color: white;
        border-left-color: var(--ax-purple);
    }



    100%{
        border-bottom-color: var(--ax-purple);
        border-left-color: var(--ax-purple);
    }

}


@keyframes line2_glow {
    0%{
        border-top-color: var(--ax-purple);
        border-left-color: var(--ax-purple);
    }

    29%{
            border-top-color: var(--ax-purple);
            border-left-color: var(--ax-purple);
    }
    
    30%{
        border-top-color: var(--ax-purple);
        border-left-color: white;
    }

    50%{
        border-top-color: white;
        border-left-color: var(--ax-purple);
    }

    60%{
        border-top-color: var(--ax-purple);
        border-left-color: var(--ax-purple);
    }
    100%{
        border-top-color: var(--ax-purple);
        border-left-color: var(--ax-purple);
    }

}

@keyframes line1_glow {
    0%{
        border-bottom-color: var(--ax-purple);
        border-left-color: var(--ax-purple);
    }
    
    10%{
        border-bottom-color: var(--ax-purple);
        border-left-color: white;
    }

    20%{
        border-bottom-color: white;
        border-left-color: var(--ax-purple);
    }

    30%{
        border-bottom-color: var(--ax-purple);
        border-left-color: var(--ax-purple);
    }
    100%{
        border-bottom-color: var(--ax-purple);
        border-left-color: var(--ax-purple);
    }

}