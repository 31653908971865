.customized-tooltip-content{
    background-color: white;
    padding:7px
}

.cost_graph_holder{
    display: flex;
    flex-direction: column;
    position: relative;
    left: 10px;
}

.cost_graph_title{
    text-align: left;
    text-indent: 20px;
    color: white;
    font-size: 26px;
}

.cost_breakdown_holder{
    width: 90%;
    left: 5%;
    position: relative;
    display: flex;
    flex-direction: row;
}