.kpi_holder{
    background-color: #0e0e0e00;
}

.kpi_two_subtext{
    color: var(--ax-purple);
    text-align: center;
    font-size: 20px;
    text-align: center;
    width: 50%;
    left: 25%;
    position: relative;
    margin-bottom: 50px;
}

.kpi_three_subtext{
    color: var(--ax-pink);
    text-align: center;
    font-size: 20px;
    text-align: center;
    width: 50%;
    left: 25%;
    position: relative;
    margin-bottom: 50px;
}